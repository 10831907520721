<script>
import Layout from "../../layouts/main";
import EasyDataTable from "vue3-easy-data-table";
import axios from "axios";
import "vue3-easy-data-table/dist/style.css";

import base_domain from "../../myvars";
axios.defaults.baseURL = base_domain;

export default {
  components: {
    Layout,
    EasyDataTable,
  },
  data() {
    return {
      roles: [
        { text: "staff", value: "staff" },
        { text: "verification officer", value: "verification officer" },
      ], // Array of roles coming from the backend
      selectedRole: "staff",
      branchs: [
        { text: "Arima", value: "Arima" },
        { text: "Barataria", value: "Barataria" },
        { text: "Chaguanas", value: "Chaguanas" },
        { text: "Port of Spain", value: "Port of Spain" },
        { text: "Sande Grande", value: "Sande Grande" },
        { text: "San Fernando", value: "San Fernando" },
        { text: "St. Joseph - La Joya", value: "St. Joseph - La Joya" },
        { text: "Tobago", value: "Tobago" },
      ],
      selectedBranch: "Arima",
      firstName: "",
      lastName: "",
      email: "",
      admin: "yes",
      addStaff: false,

      edit_firstName: "",
      edit_lastName: "",
      edit_email: "",
      edit_selectedRole: "",
      edit_selectedBranch: "",
      successMessage: false,
      archiveStaff: false,
      viewStaff: false,
      editStaff: false,
      selectFilterSort: null,
      password: this.value,
      headers: [
        { text: "Name", value: "staffname" },
        { text: "Staff_Id", value: "Staff_Id" },
        { text: "Emails", value: "staffemail" },
        { text: "Branch Name", value: "staffbranch" },
        { text: "roles", value: "staffrole" },
        { text: "Status", value: "staffstatus" },
        { text: "Action", value: "staffaction" },
        { text: "Active/Inactive", value: "stafftoggleBtn" },
      ],
      arrItem: [],
    };
  },
  mounted() {
    if (this.auto == "true" || this.auto == 1) {
      this.generate();
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    printCSV() {
      const rows = [
        ["Column 1", "Column 2"],
        [1, 2],
        [3, 4],
      ]; // replace with your table data
      const csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "table.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    duplicateItem() {
      this.itemsss.push(Date.now());
    },
    generate() {
      let CharacterSet =
        "abcdefghijklmnopqrstuvwxyz![]{}()ABCDFASDFFG1234582475%&*$#^<>~@|";
      let password = "";
      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.password = password;
    },
    filterStatus() {
      if (this.selectFilterSort == null) {
        this.arrItem = this.items;
      } else {
        this.arrItem = this.items.filter((item) => {
          return item.staffstatus == this.selectFilterSort;
        });
      }
    },
    // viewStaffidget(id){
    //     console.warn('i im start with id ',id)
    // },
    loadData() {
      console.log("Staff Data API");
      let arrList = [];
      axios
        .get("/applications/admin_staffwise_stats/", { admin: this.admin })
        .then((response) => {
          // Handle the response
          console.log("Staff Data :", response.data);
          let responseList = response.data["staff_count"];
          responseList.forEach((item) => {
            let newItem = {
              Staff_Id: item.staff_id,
              staffname: item.staff_name,
              staffemail: item.email,
              staffbranch: item.branch || "N/A",
              staffrole: item.role,
              staffstatus: item.staff_status,
              staffaction: "edit/delete",
              toggleBtnUrl: `${item.staff_id}`,
            };

            // Push the transformed item to the existing array
            arrList.push(newItem);
          });
          console.log("NEW ARRAY DATa", arrList);
          this.arrItem = arrList;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error sending email:", error);
        });
    },

    toggleCheckbox(toggleBtnUrl, event) {
      // this.callApi(toggleBtnUrl);
      this.checkbox = !this.checkbox;
      console.log(
        "yaha se start hua h toggle button",
        toggleBtnUrl,
        this.checkbox,
        event.target.checked
      );

      axios
        .post("/accounts/toggle_staff_activation/", {
          aid: toggleBtnUrl,
          verifyit: event.target.checked,
          tn: localStorage.getItem("accessToken"),
        })
        .then((response) => {
          if (response.data.status == "SUCCESS") {
            const indexToUpdate = this.arrItem.findIndex(
              (item) => item.applicantId === parseInt(response.data.aid)
            );
            console.log(
              "status____",
              response.data.status,
              indexToUpdate,
              response.data.newstatus
            );
            console.log(this.arrItem[indexToUpdate]);
            if (indexToUpdate != -1) {
              this.arrItem[indexToUpdate].statusLog = response.data.newstatus;
            }
            console.log(this.arrItem[indexToUpdate]);
          }
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    addmember() {
      axios
        .post("/accounts/add_memeber/", {
          email: this.email,
          first_name: this.firstName,
          last_name: this.lastName,
          role: this.selectedRole,
          branch: this.selectedBranch,
        })
        .then((response) => {
          // Handle the response
          console.log("Member data sent successfully:", response.data);
          if (response.data.msg === "Memeber Added Successfully") {
            location.reload();
          }
        })

        .catch((error) => {
          // Handle any errors
          console.error("Error sending email:", error);
        });
    },
    openEditModal(staffId) {
      this.selectedStaffId = staffId;

      this.loadStaffDetails(staffId); // Method to load staff details for editing
      this.editStaff = true;
    },
    loadStaffDetails(staffId) {
      console.log("chekc the id of staff------  loadStaffDetails  -", staffId);

      axios.post(`/accounts/get_staff_memeber/${staffId}`).then((response) => {
        const staff = response.data.status;

        this.edit_firstName = staff.first_name;
        this.edit_lastName = staff.last_name;
        this.edit_email = staff.email;
        this.edit_selectedRole = staff.role;
        this.edit_selectedBranch = staff.branch_name;
      });
    },
    editmember() {
      axios
        .post("/accounts/edit_memeber/", {
          staffId: this.selectedStaffId,
          email: this.edit_email,
          first_name: this.edit_firstName,
          last_name: this.edit_lastName,
          role: this.edit_selectedRole,
          branch: this.edit_selectedBranch,
        })
        .then((response) => {
          console.log("Member data sent successfully:", response.data);
          if (response.data.msg === "Edited Successfully") {
            this.successMessage = true; // Show success message
            location.reload();  
            setTimeout(() => {
              this.successMessage = false;
              this.editStaff = false;
            }, 3000);
          }
        })

        .catch((error) => {
          // Handle any errors
          console.error("Error sending email:", error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <main class="py-4">
      <div class="row mb-3 align-items-center">
        <div class="col">
          <h2 class="font-size-22 text-theme-dark mb-0">Staff Users</h2>
        </div>
        <div class="col-auto">
          <div class="text-end">
            <router-link
              to="/archived_staff_member"
              class="mb-0 fw-medium text-theme-dark d-flex align-items-center gap-2"
              >Archived Staff members
              <i class="bx bx-right-arrow-alt"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row gx-2 mb-3">
                <div class="col"></div>
                <div class="col-md-4 col-lg-3 mt-md-0 mt-3">
                  <select
                    class="form-select form-control simpleSelectStyle"
                    v-model="selectFilterSort"
                    @change="filterStatus"
                  >
                    <option selected :value="null">Status</option>
                    <option value="Active">Active Staff</option>
                    <option value="Inactive">Inactive Staff</option>
                  </select>
                </div>
                <div class="col-md-auto mt-md-0 mt-3">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-theme-dark"
                    @click="addStaff = true"
                    >+ Add Staff Member</a
                  >
                  <b-modal
                    title="Add Staff Member"
                    v-model="addStaff"
                    hide-footer
                    centered
                  >
                    <form @submit.prevent="addmember()">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="d-block mb-3">
                            <label class="font-size-14">First Name </label>
                            <input
                              v-model="firstName"
                              type="text"
                              class="form-control simpleInputStyle"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="d-block mb-3">
                            <label class="font-size-14">Last Name </label>
                            <input
                              v-model="lastName"
                              type="text"
                              class="form-control simpleInputStyle"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="d-block mb-3">
                            <label class="font-size-14">Email </label>
                            <input
                              v-model="email"
                              type="email"
                              class="form-control simpleInputStyle"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="d-block mb-3">
                            <label class="font-size-14">Role</label>
                            <select
                              v-model="selectedRole"
                              class="form-control simpleInputStyle"
                            >
                              <option
                                v-for="role in roles"
                                :key="role.value"
                                :value="role.value"
                              >
                                {{ role.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="d-block mb-3">
                            <label class="font-size-14">Branch Name</label>
                            <select
                              v-model="selectedBranch"
                              class="form-control simpleInputStyle"
                            >
                              <option
                                v-for="branch in branchs"
                                :key="branch.value"
                                :value="branch.value"
                              >
                                {{ branch.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="d-block text-end">
                            <input
                              type="submit"
                              class="btn btn-theme btn-custom-wdth"
                              value="Add"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </b-modal>
                </div>
              </div>
              <EasyDataTable
                :headers="headers"
                :items="arrItem"
                :search-value="searchValueTable"
                border-cell
                theme-color="#df6a0d"
                :rows-per-page="10"
                buttons-pagination
                table-class-name="table-custom-style"
              >
                <template #item-staffname="{ staffname }">
                  <span class="fw-medium">
                    {{ staffname }}
                  </span>
                </template>
                <template #item-staffstatus="{ staffstatus }">
                  <span
                    class="badge badge-pill font-size-11"
                    :class="{
                      'badge-soft-success': `${staffstatus}` === 'Active',
                      'badge-soft-primary': `${staffstatus}` === 'Inactive',
                      'badge-soft-danger': `${staffstatus}` === 'N/A',
                    }"
                  >
                    {{ staffstatus }}
                  </span>
                </template>
                <template #item-staffaction="{ Staff_Id }">
                  <div class="d-flex button-row">
                    <b-button
                      class="btn-sm btn-theme-dark"
                      title="View Application"
                      :to="`/single_staff/${Staff_Id}`"
                    >
                      <i class="mdi mdi-eye"></i> View
                    </b-button>
                    <!-- <b-button
                      class="btn-sm btn-theme-dark"
                      title="Edit Application"
                      :to="`/edit_staff/${Staff_Id}`"
                    >
                      <i class="mdi mdi-eye"></i> Edit
                    </b-button> -->
                    <div class="col-md-auto mt-md-0 mt-3">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-theme-dark"
                        @click="openEditModal(Staff_Id)"
                        ><i class="mdi mdi-eye"></i> Edit</a
                      >
                      <b-modal
                        title="Edit Staff Member"
                        v-model="editStaff"
                        hide-footer
                        centered
                      >
                        <form @submit.prevent="editmember()">
                          <p
                            v-if="successMessage"
                            style="color: green; font-size: 18px"
                          >
                            Successfully edited. Please wait...
                          </p>

                          <div class="row">
                            <div class="col-md-6">
                              <div class="d-block mb-3">
                                <label class="font-size-14">First Name </label>
                                <input
                                  v-model="edit_firstName"
                                  type="text"
                                  class="form-control simpleInputStyle"
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="d-block mb-3">
                                <label class="font-size-14">Last Name </label>
                                <input
                                  v-model="edit_lastName"
                                  type="text"
                                  class="form-control simpleInputStyle"
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="d-block mb-3">
                                <label class="font-size-14">Email </label>
                                <input
                                  v-model="edit_email"
                                  type="email"
                                  class="form-control simpleInputStyle"
                                  placeholder="Email"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="d-block mb-3">
                                <label class="font-size-14">Role</label>
                                <select
                                  v-model="edit_selectedRole"
                                  class="form-control simpleInputStyle"
                                >
                                  <option
                                    v-for="role in roles"
                                    :key="role.value"
                                    :value="role.value"
                                  >
                                    {{ role.text }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="d-block mb-3">
                                <label class="font-size-14">Branch Name</label>
                                <select
                                  v-model="edit_selectedBranch"
                                  class="form-control simpleInputStyle"
                                >
                                  <option
                                    v-for="branch in branchs"
                                    :key="branch.value"
                                    :value="branch.value"
                                  >
                                    {{ branch.text }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="d-block text-end">
                                <input
                                  type="submit"
                                  class="btn btn-theme btn-custom-wdth"
                                  value="Save changes"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </b-modal>
                    </div>
                  </div>
                </template>
                <template #item-stafftoggleBtn="{ toggleBtnUrl, staffstatus }">
                  <label
                    class="switch"
                    v-if="staffstatus == 'Active' || staffstatus == 'Inactive'"
                  >
                    <input
                      type="checkbox"
                      class="checkBoxCstmStl"
                      :checked="staffstatus == 'Active'"
                      @click="toggleCheckbox(toggleBtnUrl, $event)"
                    />
                    <div class="slider round"></div>
                  </label>
                </template>
              </EasyDataTable>
            </div>
          </div>
          <b-modal
            title="Archive Staff Member"
            v-model="archiveStaff"
            hide-footer
            centered
          >
            <h5 class="text-center font-size-18">
              Do you really want to archive<br />
              this staff member?
            </h5>
            <div class="d-flex justify-content-center gap-2">
              <button
                class="btn btn-theme"
                type="button"
                data-bs-dismiss="modal"
              >
                Yes
              </button>
              <button
                class="btn btn-theme-dark"
                type="button"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </b-modal>
          <!-- <b-modal title="View Staff Member" v-model="viewStaff" hide-header hide-footer centered>
                        <div class="position-absolute end-10 font-size-15" type="button" data-bs-dismiss="modal"><i
                                class="mdi mdi-close-thick"></i>
                        </div>
                        <div class="text-center mb-3">
                            <img src="../../../assets/images/users/stafffour.png" class="img-fluid mb-2">
                            <h5 class="mb-0 font-size-18 fw-medium text-uppercase"> Deepak Sajwan</h5>
                            <p class="text-uppercase mb-0 fw-medium font-size-12">Staff Member</p>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="card mb-2">
                                    <div class="card-body d-flex gap-2">
                                        <p class="fw-bold mb-0">
                                            Email:
                                        </p>
                                        <p class="mb-0">deepak123@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card mb-2">
                                    <div class="card-body d-flex gap-2">
                                        <p class="fw-bold mb-0">
                                            First Name:
                                        </p>
                                        <p class="mb-0">Deepak</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card mb-2">
                                    <div class="card-body d-flex gap-2">
                                        <p class="fw-bold mb-0">
                                            Last Name:
                                        </p>
                                        <p class="mb-0">Sajwan</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card mb-2">
                                    <div class="card-body d-flex gap-2">
                                        <p class="fw-bold mb-0">
                                            Status:
                                        </p>
                                        <p class="mb-0">Active </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card mb-2">
                                    <div class="card-body d-flex gap-2">
                                        <p class="fw-bold mb-0">
                                            Password:
                                        </p>
                                        <p class="mb-0">f45wrg</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-modal> -->
          <b-modal
            title="Edit Staff Member"
            v-model="editSta"
            hide-footer
            centered
          >
            <form>
              <div class="d-block mb-3">
                <label class="font-size-14">First Name </label>
                <input
                  type="text"
                  class="form-control simpleInputStyle"
                  placeholder="First Name"
                  value="Deepak"
                />
              </div>
              <div class="d-block mb-3">
                <label class="font-size-14">Last Name </label>
                <input
                  type="text"
                  class="form-control simpleInputStyle"
                  placeholder="Last Name"
                  value="Sajwan"
                />
              </div>
              <div class="d-block mb-3">
                <label class="font-size-14">Email </label>
                <input
                  type="email"
                  class="form-control simpleInputStyle"
                  placeholder="Email"
                  value="deepak123@gmail.com"
                />
              </div>
              <div class="d-block mb-3">
                <label class="font-size-14">Password </label>
                <div class="input-group">
                  <input
                    type="password"
                    class="form-control simpleInputStyle"
                    value="fetfwerwerf"
                    placeholder="Password"
                  />
                  <!-- <span class="input-group-btn"> -->
                  <!-- <button type="button" class="btn btn-theme py-1" @click="generate()"> -->
                  <!-- <span class="mdi mdi-refresh font-size-18"></span> -->
                  <!-- </button> -->
                  <!-- </span> -->
                </div>
              </div>
              <div class="d-block mb-3">
                <label class="font-size-14">Change Status </label>
                <select class="form-select form-control simpleSelectStyle">
                  <option selected>Active</option>
                  <option>Inactive</option>
                </select>
              </div>
              <div class="d-block text-end">
                <input
                  type="submit"
                  class="btn btn-theme btn-custom-wdth"
                  value="Save Changes"
                />
              </div>
            </form>
          </b-modal>
        </div>
      </div>
    </main>
  </Layout>
</template>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.checkBoxCstmStl {
  opacity: 0;
  visibility: hidden;
}
input:checked + .slider {
  background-color: #f48226;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f48226;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px; /* Adjust the border-radius as needed */
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px; /* Adjust the height of the circle as needed */
  width: 16px; /* Adjust the width of the circle as needed */
  left: 2px; /* Adjust the left offset as needed */
  bottom: 2px; /* Adjust the bottom offset as needed */
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(
    18px
  ); /* Adjust the translateX value as needed */
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
</style>